.text-animation {
  background: black;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-wrapper {
  position: relative;
  font-size: 15px;
}

.text-wrapper h2 {
  z-index: 4;
  color: #fff;
  font-size: 10vw;
  white-space: nowrap;
  position: absolute;
  transform: translate(-50%, -62%);
  font-weight: 500;
}

.text-wrapper h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 0.8px #fff;
}

.text-wrapper h2:nth-child(2) {
  color: #fff;
  animation: wave 1.5s ease-in-out infinite;
}

@keyframes wave {
  /* start point */
  0%,
  100% {
    clip-path: polygon(
      0% 46%,
      17% 45%,
      34% 50%,
      56% 61%,
      69% 62%,
      86% 60%,
      100% 51%,
      100% 100%,
      0% 100%
    );
  }
  /* Mid point */
  50% {
    clip-path: polygon(
      0% 59%,
      16% 64%,
      33% 65%,
      52% 61%,
      70% 52%,
      85% 47%,
      100% 48%,
      100% 100%,
      0% 100%
    );
  }
}
