body {
  margin: 0;
  font-family: "Roboto Flex","Noto Kufi Arabic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

html,body{
  margin:0;
  padding:0;

  }
/* html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh; 
} */
/* body { position: fixed; height: 100%} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
